import { useDispatch, useSelector } from "react-redux";

import { profileDoor } from "../../utils/Store";

const Header = () => {

  const profileDispatcher = useDispatch();
const isDark = useSelector(state=>state.isDark);

  return (
    <div
      style={{
        backgroundColor: isDark?"#202c33":"#c6b3ff",
        height: "50px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        boxShadow:"0px 10px 10px -10px rgba(0, 0, 0, 0.5)",
        alignItems: "center",
        
        position:'relative'
      }}
    >
      {/**DP */}
      <div onClick={()=>{profileDispatcher(profileDoor(true))}}
        style={{
backgroundImage:`url('${"https://i.ibb.co/kQpCwpT/Whats-App-Image-2023-05-25-at-12-11-07-AM.jpg"}')`,
          borderRadius: "40px",
          backgroundSize:'cover',
          width: "40px",
          height: "40px",
          cursor: "pointer",
        }}
      ></div>
{/**menu + other stuff */}
      <div style={{display:'flex', justifyContent:'space-between', padding:'20px'}}>
        
        
       {/* <div>ss</div>
       <div>men</div>
       <div>ss</div> */}
 <div style={{color:isDark?'white':'black', fontWeight:'200', fontSize:18,}}>SarbZone</div>
      </div>
      
    </div>
    
  
  );
};

export default Header;
