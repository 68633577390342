import axios from "axios";
import { motion, useDragControls, wrap } from "framer-motion";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat, updateBlogDetails } from "../../../utils/Store";

const ProjectComponent = ({
  imageUrl,
  title,
  appUrl = "",
  blogUrl = "",
  gitUrl = "",
  androidUrl = "",
  iosUrl = "",
  text,
  scrollSet
}) => {
  const URL = imageUrl;

  const scrollRef = useRef();

  const isDark = useSelector(state=>state.isDark);

  const [projectModel, setProjectModel] = useState(false);
  const text2 = !projectModel? text.slice(0, 150) + "... ":text;
  const [isLoading, setLoadingStatus] = useState(false);


  const widthVariants = !projectModel
    ? {
        read: { width: 480 },
      }
    : {
        read: { width: 900 },
      };


      const scrollInView = () => {
       scrollSet(scrollRef.current.offsetTop-20);
      };


const blogDispatcher = useDispatch();
      const renderBlog = (blogId)=>{
setLoadingStatus(true);
        
        axios.post('/getBlog',{id:blogId}).then(response=>{

        const {id, title, imageUrl, text} = response.data;

setLoadingStatus(false);
        blogDispatcher(updateBlogDetails({blogName:title, blogId:id, blogText:text, blogPhoto:imageUrl}));
        blogDispatcher(setCurrentChat({title:'Blog --> '+title}));
    




        }).catch(err=>{console.log(err.message)});



      }




  return (
    <motion.div ref={scrollRef}
    
    className="projectComponent w3-card-4"

      animate={{ ...widthVariants.read, y:0 }}
      transition={{damping:0}}
      style={{y:100, backgroundColor:isDark?'':'#c6b3ff'}}
     
    >
      {/**Photo */}
      <div>
        {" "}
        <div
          style={{
            width: 200,
            height: 200,
            borderRadius: 10,
            backgroundImage: `url('${URL}')`,
            backgroundSize: "cover",
          }}
        ></div>
        {/**title */}
        <div
          style={{ textAlign: "center", color: isDark?"rgb(233, 237, 239)":'black', fontFamily:'Pacifico', fontSize:20 }}
        >
          {title}
        </div>
      </div>
      {/**Text */}
      <div
        style={{
          marginLeft: 30,
          color: isDark?"rgb(233, 237, 239)":'black',
          width: 'auto',
        }}
      ><div style={{width:!projectModel?200:600}}>
        <span 
          dangerouslySetInnerHTML={{ __html: text2 }}
        ></span></div>
        {!projectModel && (
          <motion.span
            onClick={() => {
              setProjectModel(true);scrollInView();
            }}
            whileTap={{ color: "gray" }}
            style={{ cursor: "pointer", color: isDark?"cyan":'purple', fontSize: 15 }}
          >
            read more
          </motion.span>
        )}
        {projectModel && (
          <motion.span
            onClick={() => {
              setProjectModel(false);
            }}
            whileTap={{ color: "gray" }}
            style={{ cursor: "pointer", color: "red", fontSize: 15 }}
          >
            <br />
            read less
          </motion.span>
        )}
        {/**buttons */}
        <br />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {appUrl !== "" && (
            <a href={appUrl} target="_blank">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  background: "#111b21",
                  color:'white',
                  borderRadius: 10,
                  cursor: "pointer",
                  position:'relative'
                }}
                className="fa fa-external-link"
              ></motion.div>
            </a>
          )}
          {iosUrl !== null && (
            <a href={iosUrl} target="_blank">
              {" "}
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  color:'white',
                  background: "#111b21",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-apple"
              ></motion.div>
            </a>
          )}
          {androidUrl !== null && (
            <a href={androidUrl} target="_blank">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  background: "#111b21",
                  color:'white',
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-android"
              ></motion.div>
            </a>
          )}
          {gitUrl !== "" && (
            <a href={gitUrl} target="_blank">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  background: "#111b21",
                  color:'white',
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-git"
              ></motion.div>
            </a>
          )}
          {blogUrl !== "" && (
            
              <motion.div

              onClick={()=>{renderBlog(parseInt(blogUrl.replace('/',"").replace("blog","")))}}

                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  color:'white',
                  background: "#111b21",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className={isLoading?'':'fa fa-rss'}
              >{isLoading?<span className="fa fa-spinner w3-spin"></span>:""}</motion.div>
            
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectComponent;
