import { createSlice, configureStore } from "@reduxjs/toolkit";


const descriptions={
  about: "🤖 Ask me anything about me.",
  projects:'👨‍💻My Projects, recent ones are on Top.',
  skills:'🛠️ Hammer, Wrench, ⚛️ React, Node. LOL',
  blog:'📝 Blogs about some of my 👨‍💻Projects.',
  contact:'📱Instagram or 💬Whatsapp would be quick..'
};

const photos={ about: "https://picsum.photos/200",
projects:'https://picsum.photos/200',
skills:'https://picsum.photos/200',
blog:'https://picsum.photos/200',
contact:'https://picsum.photos/200'};


const dates= { about: "Today",
projects:'Tuesday',
skills:'Monday',
blog:'12:00AM',
contact:'5:00PM'};




const dataSlice = createSlice({
  name: "dataSlice",

  initialState: {
    isDark:true,
    descriptions,dates,photos,
    chats:[],
    title: "",
    photoURL: "",
    dpURL:'https://i.ibb.co/kQpCwpT/Whats-App-Image-2023-05-25-at-12-11-07-AM.jpg',
    profileWindow:false,
    aboutOpened: false,
    thinking: false,
    aboutChat: [
      {
        message: "Hi There I am Sarbjeet Singh and this is an 🤖 AI version of myself🧑‍💻.<br><br> and you can ask me questions about myself like:<br><br>🏫Educational Background📚<br>📄Certifications📄<br>🔨Skills⚙️<br>🧑‍💻Projects🎦 etc..<br>Thanks and enjoy..😁😁<br>",
        who: 0,
      },
    ],
    blogDetails: { blogName: "", blogId: 0, blogText: "", blogPhoto: "" },
    projects: [],
    blogs: [],
   
    skills: [],
  },
  reducers: {

setDP(state, action){

  state.dpURL = action.payload;

},

setThemeToDark(state, action){

state.isDark = action.payload;

},
    profileDoor(state, action){

state.profileWindow = action.payload; 

    },
    saveChats(state, action){

state.chats = action.payload;




    },
    


    saveProjects(state, action) {

      state.projects = [...action.payload];


    },
    saveBlogs(state, action) {
      state.blogs = [...action.payload];
    },
    
    saveSkills(state, action) {
      state.skills = [...action.payload];
    },

    setCurrentChat(state, action) {
      state.title = action.payload.title;
      if (!!action.payload.photoURL) {
        state.photoURL = action.payload.photoURL;
      }
    },
    freezeAbout(state) {
      state.aboutOpened = true;
    },
    startThinking(state) {
      state.thinking = true;
    },
    stopThinking(state) {
      state.thinking = false;
    },
    saveMessage(state, action) {
      state.aboutChat = [...state.aboutChat, action.payload];
    },
    updateBlogDetails(state, action) {
      state.blogDetails = action.payload;
    },
  },
});

export const {
  setCurrentChat,
  freezeAbout,
  startThinking,
  stopThinking,
  saveMessage,
  updateBlogDetails,
  saveBlogs,saveProjects,saveSections,saveSkills, profileDoor, setDP, saveChats,setThemeToDark
} = dataSlice.actions;

const store = configureStore({ reducer: dataSlice.reducer });

export default store;
