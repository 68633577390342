import { useSelector } from "react-redux";
import ReactDOM from 'react-dom';
import DP from "../ChatSection/DP";
import { useState } from "react";
import Theme from "../Theme";
const MessageHeader = () => {
  const photoURL = useSelector((state) => state.photoURL);
  const title = useSelector((state) => state.title);
  const thinking = useSelector((state) => state.thinking);
  const [enlargeDP, toggleDPsize] = useState(false);

  const isDark = useSelector((state) => state.isDark);
  
  return (
    <div

    
      style={{
        backgroundColor: isDark?"#202c33":"#c6b3ff",
        height: "50px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        boxShadow:"0px 10px 10px -10px rgba(0, 0, 0, 0.5)",
        position:"relative",
        zIndex:999,


        alignItems: "center",
      }}
    >

      {/**photo title thiniknng------------theme separator */}
<div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
      
      {/**photo and title */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/**Photo */}
        <div
        onClick={()=>{toggleDPsize(prev=>!prev)}}
          style={{
            width: 40,
            height: 40,
            borderRadius: 40,
            backgroundImage: `url('${photoURL}')`,
            backgroundSize: "cover",
            cursor:'pointer'
          }}
        ></div>
              {enlargeDP && ReactDOM.createPortal(<DP DP={photoURL}  hide={()=>{toggleDPsize(prev=>!prev)}} />, document.getElementById('top'))}

        {/**Title */}
        <div style={{display:"flex", flexDirection:'column', justifyContent:'center', alignContent:'center'}}>
        <div style={{ marginLeft: 20, fontSize: 18, color: isDark?"#e9edef":'#111b21', fontWeight:'200' }}>
          {title}
        </div>
        {/**typing */}
       {thinking && title==="About Me" &&  <div style={{textAlign:'center', fontSize:10,color:isDark?'white':'black'}}>Thinking...</div>}
        </div>
      </div>
<Theme/>
      </div>
    </div>
  );
};

export default MessageHeader;
