import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat, updateBlogDetails } from "../../../utils/Store";
import {motion} from 'framer-motion';

const BlogComponent = ({ id, title, text, imageUrl, createdAt }) => {

const isDark = useSelector(state=>state.isDark);
  const blogDispatcher = useDispatch();

  const read = ()=>{

    blogDispatcher(updateBlogDetails({blogName:title, blogId:id, blogText:text, blogPhoto:imageUrl}));
    blogDispatcher(setCurrentChat({title:'Blog --> '+title}));


  }




  return (
    <motion.div
    animate={{x:0}}
    transition={{damping:0}}
    whileHover={{ cursor:'pointer', scale:1.02, boxShadow:'0 0 10px gray'}}
onClick={read}
      style={{x:-100,
        margin: 10,
        marginLeft: 20,
        padding: 10,
        width: "45%",
        backgroundColor: isDark?"#202c33":'#c6b3ff',
        borderRadius: 10,
        boxShadow:'none',
        minWidth:400
      }}
    >
      {/**image and title */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div
          style={{
            backgroundSize: "cover",
            backgroundImage: `url('${imageUrl}')`,
            width: 100,
            height: 100,
            borderRadius: 10,
          }}
        ></div>
        <div
          style={{
            fontFamily:'Pacifico',
            fontSize: 20,
            color: isDark?"rgb(233, 237, 239)":'black',
            marginRight: 10,
          }}
        >
          {title}
        </div>
      </div>
      <div style={{ color: isDark?"rgb(233, 237, 239)":'black' }}>{text.slice(0, 200) + "..."}</div>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <div
        onClick={read}
          style={{ backgroundColor: "#111b21", color: "rgb(233, 237, 239)" ,fontFamily:'Pacifico'}}
          className="w3-button w3-round-xlarge"
        >
          read <span className="fa fa-arrow-right"></span>
        </div>
      </div>
    </motion.div>
  );
};

export default BlogComponent;
