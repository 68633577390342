import { useDispatch, useSelector } from "react-redux";
import { profileDoor } from "../../utils/Store";

import ReactDOM from 'react-dom';
import { useState } from "react";
import DP from "./DP";
const PhoneHeader = ({title, photoURL, goBack}) => {
    const profileDispatcher = useDispatch();
    const [enlargeDP, toggleDPsize] = useState(false);
    
const currentTitle = useSelector(state=>state.title);
const isDark = useSelector(state=>state.isDark);
  return (
    <div
     className="w3-card-4"
      style={{
        backgroundColor: isDark?"#202c33":"#c6b3ff",
        height: "50px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position:"relative",
        zIndex:99
      }}
    >


        <div style={{display:"flex",  justifyContent:'center', alignItems:'center'}}>
            {/**back*/}
          { currentTitle!=="" && <div onClick={()=>{goBack();}} style={{fontWeight:'bold', cursor:'pointer', width:25, height:25, borderRadius:25, backgroundColor:'gray', display:"flex", justifyContent:'center', alignItems:'center', textAlign:'center'}} className="fa fa-angle-left w3-margin-right w3-large w3-text-white "></div>}
      {/**DP */}
      <div
      onClick={()=>{if(currentTitle==="") {profileDispatcher(profileDoor(true))}else{
        toggleDPsize(prev=>!prev);
      }}}
        style={{
backgroundImage:`url('${currentTitle===""?"https://i.ibb.co/kQpCwpT/Whats-App-Image-2023-05-25-at-12-11-07-AM.jpg":photoURL}')`,
          borderRadius: "40px",
          backgroundSize:'cover',
          width: "40px",
          height: "40px",
          cursor: "pointer",
        }}
      ></div>
      
      {enlargeDP && ReactDOM.createPortal(<DP DP={photoURL}  hide={()=>{toggleDPsize(prev=>!prev)}} />, document.getElementById('top'))}

      
      </div>
{/**menu + other stuff */}
      <div style={{display:'flex', justifyContent:'space-between', padding:'20px'}}>
        
        
       <div style={{color:isDark?'white':'black', fontWeight:'200', fontSize:20,fontFamily:'Pacifico'}}>{currentTitle===""?"Sarbzone":currentTitle}</div>
       

      </div>
      
    </div>
    
  
  );
};

export default PhoneHeader;
