import { useSelector } from "react-redux";
import MessageHeader from "./MessageHeader";
import Welcome from "./Welcome";
import AboutMe from "./AboutMe/AboutMe";
import Projects from "./Projects/Projects";

import Skills from "./Skills/Skills";
import Blogs from "./Blog/Blogs";
import ContactMe from "./ContactMe/ContactMe";
import { Suspense } from "react";
import BlogReading from "./Blog/BlogReading";
import WelcomeSpinner from "../WelcomeSpinner";





const MessageSection =()=>{ 


const title = useSelector(state=>state.title);
const isDark = useSelector(state=>state.isDark);
const {blogId, blogName, blogText, blogPhoto} = useSelector(state=>state.blogDetails);



return (<div style={{width:'70%', borderLeft:isDark?'solid rgba(134,150,160,0.15)':'0.5px solid rgba(134,150,160,0.35)', borderWidth:'0.5px',marginTop:'20px', marginRight:'20px', marginBottom:'20px', position:"relative"}}>

{title==="" && <Suspense   fallback={<WelcomeSpinner/>}><Welcome/></Suspense>}
{/**MEssage HEader */}

{title!=="" && !title.includes("Blog -->") && <MessageHeader/>}

{title==="About Me" && <AboutMe/>}
{title==="Projects" && <Projects/>}
{title==="Skills" && <Skills/>}
{title==="Blog" && <Blogs/>}
{title==="Contact Me" && <ContactMe/>}
{title==="Blog --> "+blogName && <BlogReading id={blogId} title={blogName} photoURL={blogPhoto} text={blogText} />}


</div>);
}

export default MessageSection;