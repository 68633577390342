import { motion, useDragControls, wrap } from "framer-motion";
import { useRef, useState } from "react";
import ImageLoader from "../../ImageLoader";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setCurrentChat, updateBlogDetails } from "../../../utils/Store";

const PhoneProjectComponent = ({
  imageUrl,
  title,
  appUrl = "",
  blogUrl = "",
  gitUrl = "",
  androidUrl = "",
  iosUrl = "",
  text,
  scrollSet
}) => {
  const URL = imageUrl;

  const scrollRef = useRef();
const isDark = useSelector(state=>state.isDark);
  const [projectModel, setProjectModel] = useState(false);
  const text2 = !projectModel? text.slice(0, 150) + "... ":text;
  
const [isLoading, setLoadingStatus] = useState(false);
 
  const blogDispatcher = useDispatch();
  const renderBlog = (blogId)=>{
setLoadingStatus(true);
    
    axios.post('/getBlog',{id:blogId}).then(response=>{

    const {id, title, imageUrl, text} = response.data;

setLoadingStatus(false);
    blogDispatcher(updateBlogDetails({blogName:title, blogId:id, blogText:text, blogPhoto:imageUrl}));
    blogDispatcher(setCurrentChat({title:'Blog --> '+title}));





    }).catch(err=>{console.log(err.message)});



  }

    



  return (
    <motion.div ref={scrollRef}
    
    

     className="w3-card-4"
     
      style={{ backgroundColor:isDark?'':'#c6b3ff', padding:10, marginTop:20, marginLeft:20, marginRight:20, borderRadius:10, flexDirection:'column', display:'flex', justifyContent:'center', alignItems:'center'}}
     
    >
      {/**Photo */}
      
        {/* <div
          style={{
            width: 150,
            height: 150,
            borderRadius: 10,
            backgroundImage: `url('${URL}')`,
            backgroundSize: "cover",
            marginBottom:10,
           
          }}
        ></div> */}

<ImageLoader height={150} width={150} url={URL} size={25} styles={{borderRadius:20}} />


        {/**title */}
        <div
          style={{ textAlign: "center", color: isDark?"rgb(233, 237, 239)":'black', fontFamily: "Pacifico", marginBottom:10, fontSize:20, marginTop:10 }}
        >
          {title}
        </div>
      
      {/**Text */}
      <div
        style={{
          marginLeft: 30,
          color: isDark?"rgb(233, 237, 239)":'black',
          width: 'auto',
        }}
      ><div >
        <span 
          dangerouslySetInnerHTML={{ __html: text2 }}
        ></span></div>
        {!projectModel && (
          <motion.span
            onClick={() => {
              setProjectModel(true);
            }}
            whileTap={{ color: "gray" }}
            style={{ cursor: "pointer", color: isDark?"cyan":'purple', fontSize: 15 }}
          >
            read more
          </motion.span>
        )}
        {projectModel && (
          <motion.span
            onClick={() => {
              setProjectModel(false);
            }}
            whileTap={{ color: "gray" }}
            style={{ cursor: "pointer", color: "red", fontSize: 15 }}
          >
            <br />
            read less
          </motion.span>
        )}
        {/**buttons */}
        <br />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {appUrl !== "" && (
            <a href={appUrl} target="_blank">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  background: "#111b21",
                  color:'white',
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-external-link"
              ></motion.div>
            </a>
          )}
          {iosUrl !== null && (
            <a href={iosUrl} target="_blank">
              {" "}
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                  background: "#111b21",
                  color:'white',
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-apple"
              ></motion.div>
            </a>
          )}
          {androidUrl !== null && (
            <a href={androidUrl} target="_blank">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  color:'white',
                  paddingLeft: 15,
                  background: "#111b21",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-android"
              ></motion.div>
            </a>
          )}
          {gitUrl !== "" && (
            <a href={gitUrl} target="_blank">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  color:'white',
                  paddingLeft: 15,
                  background: "#111b21",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="fa fa-git"
              ></motion.div>
            </a>
          )}
          {blogUrl !== "" && (
            
              <motion.div
              onClick={()=>{renderBlog(parseInt(blogUrl.replace('/',"").replace('blog',"")))}}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "cyan",
                  color: "black",
                }}
                style={{
                  margin: 10,
                  padding: 10,
                  paddingRight: 15,
                  color:'white',
                  paddingLeft: 15,
                  background: "#111b21",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className={isLoading?'':'fa fa-rss'}
              >{isLoading?<span className="fa fa-spinner w3-spin"></span>:""}</motion.div>
           
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default PhoneProjectComponent;
