import { useState } from "react";
import { useSelector } from "react-redux";




const Email =()=>{ 


const [emailHover, setEmailHover] = useState(false);

const isDark = useSelector(state=>state.isDark);

return (<div className="w3-animate-zoom" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'60vh'}}>

<a style={{textDecoration:'none'}} target="_blank" href="https://www.linkedin.com/in/sarbzone/"><div onMouseLeave={()=>{setEmailHover(false);}} onMouseEnter={()=>{setEmailHover(true);}} style={{color:isDark?'cyan':'blue', fontSize:25, marginTop:10, fontWeight:'200', fontFamily:''}}><span className={`fa fa-linkedin`}></span>{ "  " }Click to 👀 my LinkedIn</div></a>

<a style={{textDecoration:'none'}} href="mailto:sagarsarb143@gmail.com"><div onMouseLeave={()=>{setEmailHover(false);}} onMouseEnter={()=>{setEmailHover(true);}} style={{color:isDark?'cyan':'blue', fontSize:25, marginTop:10, fontWeight:'200', fontFamily:''}}><span className={`fa fa-envelope${emailHover?'-open':""}`}></span>{ "  " }Click to Email Me</div></a>
<a style={{textDecoration:'none'}} href="https://wa.me/+17055006300?text=Hi there👋,%0AName:%20%20%20%0AReason:%20%20%20%0AMore Details:%20%20%20"><div onMouseLeave={()=>{setEmailHover(false);}} onMouseEnter={()=>{setEmailHover(true);}} style={{color:isDark?'cyan':'blue', fontSize:25, marginTop:10, fontWeight:'200', fontFamily:''}}><span className={`fa fa-whatsapp`}></span>{ "  " }Click to Whatsapp Me</div></a>
<a style={{textDecoration:'none'}} href="tel:+17055006300"><div onMouseLeave={()=>{setEmailHover(false);}} onMouseEnter={()=>{setEmailHover(true);}} style={{color:isDark?'cyan':'blue', fontSize:25, marginTop:10, fontWeight:'200', fontFamily:''}}><span className={`fa fa-phone`}></span>{ "  " }Do not Click to Call 😂</div></a>


</div>);
}

export default Email;