import { useSelector } from "react-redux";
import ImageLoader from "../ImageLoader";
import { motion } from "framer-motion";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useState } from "react";

const DP = ({hide, DP}) => {
  
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
const [animate, updateAnimation] = useState({});
const [animateParent, updateAnimationParent] = useState({backdropFilter:"blur(8px)"});
  return (
    <motion.div

    animate={animateParent}

    onClick={()=>{

        updateAnimation({scale:0});
        updateAnimationParent({backdropFilter:"blur(0px)", backgroundColor:'transparent'});

        setTimeout(() => {
            hide();
        }, 500);

    }}

      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        position: "absolute",
        top: 0,
        zIndex: 9999,
        
      }}
    >
        <motion.div animate={animate}>
      <ImageLoader url={DP} size={30} width={isSmallDevice?300:500} height={isSmallDevice?300:500} />
      </motion.div>
    </motion.div>
  );
};

export default DP;
