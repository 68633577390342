import axios from "axios";
import { useEffect, useState } from "react";
import BlogComponent from "./BlogComponent";
import { useDispatch, useSelector } from "react-redux";
import { saveBlogs } from "../../../utils/Store";
import { useMediaQuery } from "@uidotdev/usehooks";
import PhoneBlogComponent from "./PhoneBlogComponent";

const Blogs = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 1300px)");
  const isPhone = useMediaQuery("only screen and (max-width : 768px)");
const isDark = useSelector(state=>state.isDark);
  const blogs = useSelector(state=>state.blogs);
const blogDispatcher = useDispatch();
  useEffect(() => {

    if(blogs.length===0){
      axios
      .post("/getBlogs")
      .then((response) => {
       blogDispatcher(saveBlogs(response.data));
      })
      .catch((err) => {
        console.log(err.message);
      });

    }
   
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        height: "92%",
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "gray transparent",
        scrollBehavior: "smooth",
        paddingTop:10,
        backgroundColor:isDark?'transparent':'#efeae2',
        justifyContent:!isSmallDevice?'flex-start':'center'
       
      }}
    >
        
      {!isPhone&& blogs.map((i) => (
        <BlogComponent key={i.id} {...i} />
      ))}

{isPhone&& blogs.map((i) => (
        <PhoneBlogComponent key={i.id} {...i} />
      ))}

      <div style={{width:'100%', height:200}}></div>
    </div>
  );
};

export default Blogs;
