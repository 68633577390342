/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/sarbzone.glb");
  const { actions } = useAnimations(animations, group);


  useEffect(()=>{

actions.truck.play();
actions.tyres.play();

  },[]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Monster_Truck_Blue"
          position={[0.148, 0, -0.559]}
          rotation={[0, 0.565, 0]}
        >
          <mesh
            name="TruckMesh"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh.geometry}
            material={materials.TruckMat}
          />
          <mesh
            name="TruckMesh_1"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh_1.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="TruckMesh_2"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh_2.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="TruckMesh_3"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh_3.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="TruckMesh_4"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh_4.geometry}
            material={materials["Material.004"]}
          />
        </group>
        <group
          name="Monster_Truck_Blue001"
          position={[0.148, 0, -0.559]}
          rotation={[0, 0.565, 0]}
          scale={[1, 0.983, 1]}
        >
          <mesh
            name="TruckMesh001"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh001.geometry}
            material={materials.TruckMat}
          />
          <mesh
            name="TruckMesh001_1"
            castShadow
            receiveShadow
            geometry={nodes.TruckMesh001_1.geometry}
            material={materials["Material.005"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/sarbzone.glb");