import { motion } from "framer-motion";
import { useState }  from "react";
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";
const SkillComponent = ({ text, imageUrl, title, score=10 }) => {

    const [hover, setHover] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const isDark= useSelector(state=>state.isDark);
    const handleMouseMove = (event)=>{

      const {clientX, clientY} = event;
      setPosition({ x: clientX, y: clientY });
    }




  return (
    <motion.div
    onMouseMove={handleMouseMove}


      animate={{ scale: 1, x: 0, y: 0 }}
      whileHover={{scale:1.05}}
      onMouseEnter={()=>{
        
        setHover(true)}}
      onMouseLeave={()=>{setHover(false)}}
     
      style={{ margin: 10, x: 100,  scale: 0, height:250, backgroundColor:isDark?'#202c33':'#c6b3ff', borderRadius:10, padding:20,position:'relative' }}
    >
      <div
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: "cover",
          width: 100,
          height: 100,
          borderRadius: 100,
          
        }}
      ></div>

      <div style={{margin:10, color:isDark?'rgb(233, 237, 239)':'black', fontWeight:'200', textAlign:'center'}}>{title}</div>

{hover && ReactDOM.createPortal(<div  onMouseMove={handleMouseMove} style={{position:"absolute", top:position.y, left:position.x,  width:200, backgroundColor:'rgb(233, 237, 239)', padding:10, borderRadius:20, fontSize:10, zIndex:9999, }} dangerouslySetInnerHTML={{__html:text}}></div>,document.getElementById('top'))}
<div style={{fontSize:30, fontWeight:200, color:isDark?'rgb(233, 237, 239)':'black', textAlign:'center'}}>{score}/10</div>
    </motion.div>
  );
};

export default SkillComponent;
