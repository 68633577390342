import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat } from "../../../utils/Store";
import axios from "axios";
import BlogSection from "./BlogSection";
import {motion} from 'framer-motion';
import ImageLoader from "../../ImageLoader";
import { useMediaQuery } from "@uidotdev/usehooks";







const BlogReading =({id, title, photoURL, text})=>{ 
    const isSmallDevice = useMediaQuery("only screen and (max-width : 1200px)");
    const isPhone = useMediaQuery("only screen and (max-width : 768px)");
    const [isChrome, setIsChrome] = useState(false);
    const blogDispatcher = useDispatch();
    const [sections, updateSections] = useState([]);
    const isDark = useSelector(state=>state.isDark);

    const goBack  = ()=>{

        blogDispatcher(setCurrentChat({title:'Blog', photoURL:''}));

    }


    useEffect(()=>{

        const userAgent = window.navigator.userAgent;
        setIsChrome(userAgent.includes('Chrome'));

axios.post('/getSections',{blogId:id}).then(response=>{

    updateSections(response.data);


}).catch(err=>{console.log(err.message)});



    },[]);




return (<motion.div animate={{x:0}} transition={{damping:0}} style={{x:100,position:'relative', overflowY:'auto', scrollbarColor:'gray transparent', scrollbarWidth:'thin', height:'98%', backgroundColor:isDark?'transparent':'#efeae2'}} >

<div className="w3-card-4" style={{textAlign:'center',  padding:isPhone?10:5, fontSize:isPhone?20:25, top:0, color:isDark?'rgb(233, 237, 239)':'black', fontWeight:'200', backgroundColor:isDark?'rgb(32, 44, 51)':"#c6b3ff", position:'sticky', borderTopWidth:10, borderTopColor:"red",fontFamily:'Pacifico'}}>
    <div onClick={goBack} style={{position:"absolute", top:0, left:0, margin:10, cursor:'pointer', fontSize:30, }}  className="fa fa-arrow-left  w3-hover-text-cyan"></div>
    {title}</div>

<div style={{display:'flex', flexDirection:isSmallDevice?'column':'row', justifyContent:'center', alignItems:'center', padding:20}}>
{/* <img style={{width:'40%', borderRadius:20, margin:20}} src={photoURL}/> */}
<ImageLoader width={isChrome?(isPhone?'40%':'20%'):'40%'} height={'auto'} styles={{borderRadius:20, margin:20}} url={photoURL} />
<div style={{margin:20, fontSize:18, fontWeight:'200', color:isDark?'rgb(233, 237, 239)':'black'}}>{text}</div>
</div>


{sections.map((i)=>{return <BlogSection key={i.id} {...i}/>})}



</motion.div>);
}

export default BlogReading;