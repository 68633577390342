import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat } from "../../utils/Store";
import { useMediaQuery } from "@uidotdev/usehooks";
import {motion} from 'framer-motion';
const ChatComponent = ({ photoURL, title, description, time }) => {
  const [clicked, updateClickedStatus] = useState(false);
  const [hovered, updateHoveredStatus] = useState(false);
  const isDark = useSelector(state=>state.isDark);
  const [bgColor, updateBgColor] = useState("transparent");
  const currentTitle = useSelector((state) => state.title);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
const isThinking = useSelector(state=>state.thinking);
  const chatDispatch = useDispatch();
const [showNotification, setNotification] = useState(false);
const [activeWatch, setActiveWatch] = useState(false);
  const openChat = () => {
    chatDispatch(setCurrentChat({ title: title, photoURL: photoURL }));
  };

  useEffect(() => {
    //#202c33

    if(isThinking && currentTitle!=="About Me"){
setActiveWatch(true);
    }

    if(activeWatch && !isThinking && currentTitle!=="About Me"){
      setNotification(true);
    }

    if(currentTitle==="About Me"){
      setActiveWatch(false);
      setNotification(false);
    }



    if (clicked) {
      updateBgColor(isDark?"#2a3942":"#f0f2f5");
    }else if(hovered){
      updateBgColor(isDark?"#202c33":'rgba(100,270,270,0.1)');
    }else{
      updateBgColor('transparent');
    }

    if (title === currentTitle || title===currentTitle.slice(0,4)) {
      updateClickedStatus(true);
    } else {
      updateClickedStatus(false);
    }
  }, [currentTitle, hovered,clicked, activeWatch, showNotification, isDark]);

  return (
    <>
      <motion.div

animate={{x:0}}
transition={{damping:0}}

        style={{
          x:-100,
          display: "flex",
          paddingRight: "0px",
          paddingLeft: "20px",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: bgColor,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          updateHoveredStatus(true);
        }}
        onMouseLeave={() => {
          updateHoveredStatus(false);
        }}
        onClick={openChat}
      >
        {/**Photo */}
        <div
          style={{
            backgroundImage: `url('${photoURL}')`,
            backgroundSize: "cover",
            width: 50,
            height: 50,
            borderRadius: 50,
          }}
        ></div>

        {/**column layout name and time/message */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            padding: "10px",
            borderBottom: isDark?"solid rgba(134,150,160,0.15)":'0.5px solid rgba(134,150,160,0.35)',
            borderWidth: "0.5px",
            paddingRight: 0,
          }}
        >
          {/**name and time */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

              color: isDark?"rgb(233, 237, 239)":'black',
              width: "100%",
            }}
          >
            <div style={{ fontSize: 18, display:'flex'}}>{title}{title==="About Me" && activeWatch && showNotification && <motion.div initial={{scale:0.5}} transition={{damping:0, repeat:'Infinity'}} animate={{scale:[1,0.5,1]}} style={{marginLeft:20, fontSize:10, width:20, height:20, borderRadius:20, backgroundColor:'rgb(0, 92, 75)', display:'flex', justifyContent:'center', alignItems:'center', color:'white'}}>1</motion.div>}</div>
            <div
              style={{ marginRight: "20px", fontSize: 13, fontWeight: "200" }}
            >
              {time}
            </div>
          </div>

          {/**Chat */}
          <div
            style={{
              color: isDark?"rgb(233, 237, 239)":'gray',

              paddingBottom: "10px",
              fontSize: isSmallDevice?13:15,
            }}
          >
            {description}
          </div>
        </div>
        
      </motion.div>
    </>
  );
};

export default ChatComponent;
