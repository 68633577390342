

import { Canvas, useFrame } from '@react-three/fiber';
import {OrbitControls, PerspectiveCamera} from '@react-three/drei';
import { Model } from '../Model';







const WelcomeScene = () => {

    

 return <Canvas   style={{width:"100%", height:'100%'}}>


<PerspectiveCamera  makeDefault  position={[0,8,5]} />


<OrbitControls maxPolarAngle={1.5708} autoRotate enableZoom={false} enablePan={false}/>




<Model/>














<ambientLight  args={['white', 0.3]}/>
<pointLight    args={['white', 1]}  position={[0,10,10]}/>
</Canvas>;
};
export default WelcomeScene;