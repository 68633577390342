import { Suspense, useEffect, useState } from 'react';
import './App.css';
import ChatSection from './Components/ChatSection/ChatSection';
import MessageSection from './Components/MessageSection/MessageSection';
import { useMediaQuery } from "@uidotdev/usehooks";
import Welcome from './Components/MessageSection/Welcome';
import WelcomeSpinner from './Components/WelcomeSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { saveChats, setDP, setThemeToDark } from './utils/Store';
import axios from 'axios';
import Loading from './Components/Loading';
function App() {


  

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const [isLoading, setLoading] =useState(true);

const [showWelcome, setWelcome] = useState(true);
const chats = useSelector(state=>state.chats);
const chatDispatcher = useDispatch();
const themeDispatcher = useDispatch();
  useEffect(() => {


    const theme = window.localStorage.getItem('theme');
if(theme==='dark'){
      themeDispatcher(setThemeToDark(true));
      document.body.style.backgroundColor="#111b21";
    }else if(theme==='light'){
      themeDispatcher(setThemeToDark(false));
     document.body.style.backgroundColor="white";
    }

    if(chats.length===0){

      axios
      .post("/getChats")
      .then((response) => {

        response.data.map(i=>{

          if(i.title==='profile'){
            chatDispatcher(setDP(i.photo));
          }

        });

        chatDispatcher(saveChats(response.data));
setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });

    }
    // Event handler for window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if(isLoading){
    return <Loading/>;
  }


  return (
//outer container
 <div style={{display:'flex', width:windowSize.width, height:windowSize.height, justifyContent:isSmallDevice?'center':'center'}}>
{/**chats section */}

{isSmallDevice&& showWelcome&& <Suspense fallback={<WelcomeSpinner/>}> <Welcome start={()=>{setWelcome(false);}}/></Suspense>}

{!showWelcome && isSmallDevice && <ChatSection/>}
{!isSmallDevice && <ChatSection/>}
{/**Messages Section */}
{!isSmallDevice && <MessageSection/>}


    </div>


   
  );
}

export default App;
