import { useState } from "react";
import Icon from "./Icon";
import Card from "./Card";
import Instagram from "./Instagram";
import Email from "./Email";
import LinkedIn from "./LinkedIn";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useSelector } from "react-redux";

const ContactMe = () => {
  const [activeTab, updateActiveTab] = useState({
    card: true,
    instagram: false,
    email: false,
    linkedin:false
  });
  const isSmall = useMediaQuery("only screen and (max-width : 900px)");
  const isDark = useSelector(state=>state.isDark);
  const tabRevealer = (key) => {
    updateActiveTab((prev) => {
      const allFalse = { card: false, instagram: false, email: false, linkedin:false };

      allFalse[key] = true;

      return allFalse;
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isSmall?"column-reverse":"row",
        height: "90%",
        width: "100%",
        paddingTop:20,
        backgroundColor:isDark?'transparent':'#efeae2'
      }}
    >
      {/**Menu */}
      <div style={{ display: "flex", flexDirection: isSmall?"row":"column", width: isSmall?"100%":"20%", justifyContent:isSmall?'space-between':'flex-start', }}>
        
      <Icon
          click={() => {
            tabRevealer("email");
          }}
          faname={"address-card-o"}
          active={activeTab.email}
        />
        
        <Icon
          click={() => {
            tabRevealer("card");
          }}
          faname={"envelope"}
          active={activeTab.card}
        />
       
        <Icon
          click={() => {
            tabRevealer("instagram");
          }}
          faname={"instagram"}
          active={activeTab.instagram}
        />
        {/* <Icon
          click={() => {
            tabRevealer("linkedin");
          }}
          faname={"linkedin"}
          active={activeTab.linkedin}
        /> */}
         
      </div>

      {/**Content for corresponding menu Item*/}
      <div style={{display:'flex',  flexDirection:'column',width:isSmall?'100%':'80%', height:isSmall?'100%':'auto'}}>

{activeTab.card && <Card/>}
{activeTab.instagram&& <Instagram/>}
{activeTab.email&& <Email/>}
{activeTab.linkedin&& <LinkedIn/>}







      </div>
    </div>
  );
};

export default ContactMe;
