import { useMediaQuery } from "@uidotdev/usehooks";
import ImageLoader from "../../ImageLoader";
import { useSelector } from "react-redux";




const BlogSection =({title,imageUrl,hasImage, text })=>{ 

    const isPhone = useMediaQuery("only screen and (max-width : 768px)");

    const isDark = useSelector(state=>state.isDark);



return (<>

<div style={{textAlign:'left', fontSize:30, color:isDark?'rgb(233, 237, 239)':'black', fontWeight:'200', marginLeft:30, fontFamily:'Pacifico'}}>{title}</div>

<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:20}}>
{/* {hasImage && <img style={{height:window.innerHeight-200, borderRadius:20, margin:20}} src={imageUrl}/>} */}

{hasImage && <ImageLoader height={!isPhone?'500px':'auto'} width={isPhone?"90%":'auto'} url={imageUrl} />}
<div style={{margin:20, fontSize:18, fontWeight:'200', color:isDark?'rgb(233, 237, 239)':'black'}} dangerouslySetInnerHTML={{__html:text}}></div>
</div>

</>);
}

export default BlogSection;