import ProjectComponent from "./ProjectComponent";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { saveProjects } from "../../../utils/Store";
import { useMediaQuery } from "@uidotdev/usehooks";
import PhoneProjectComponent from "./PhoneProjectComponent";
import { motion } from "framer-motion";
const Projects = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 968px)");
  const isPhone = useMediaQuery("only screen and (max-width : 768px)");
  const [isLoading, setLoading] = useState(true);
  const projects = useSelector((state) => state.projects);

  const projectsRef = useRef();
const isDark= useSelector(state=>state.isDark);
  const projectDispatcher = useDispatch();

  const scrollToChild = (position) => {
    const parentElement = projectsRef.current;

    parentElement.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setLoading(true);
    if (projects.length === 0) {
      axios
        .post("/getProjects")
        .then((response) => {
          projectDispatcher(saveProjects(response.data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  return (
    <motion.div
      animate={{ x: 0 }}
      transition={{ delay: 0.1, damping: 0 }}
      className="projectsContainer"
      style={{
        x: isPhone ? 1000 : 0,
        justifyContent: !isSmallDevice ? "center" : "flex-start",
        backgroundColor:isDark?'transparent':'#efeae2'
      }}
      ref={projectsRef}
    >
      {!isPhone &&
        projects.map((i) => {
          return (
            <ProjectComponent scrollSet={scrollToChild} key={i.id} {...i} />
          );
        })}
      {isPhone &&
        projects.map((i) => {
          return (
            <PhoneProjectComponent
              scrollSet={scrollToChild}
              key={i.id}
              {...i}
            />
          );
        })}

      <div style={{ height: 300, width: "100%" }}></div>
    </motion.div>
  );
};

export default Projects;
