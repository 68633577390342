import axios from "axios";
import { useEffect, useState } from "react";
import SkillComponent from "./SkillComponent";
import { useDispatch, useSelector } from "react-redux";
import { saveSkills } from "../../../utils/Store";
import { useMediaQuery } from "@uidotdev/usehooks";

const Skills = () => {
  
  const isPhone = useMediaQuery("only screen and (max-width : 700px)");
const isDark = useSelector(state=>state.isDark);
  const skills = useSelector(state=>state.skills);
const skillDispatcher = useDispatch();
  useEffect(() => {

    if(skills.length===0){

      axios
      .post("/getSkills")
      .then((response) => {
        skillDispatcher(saveSkills(response.data));
      })
      .catch((err) => {
        console.log(err.message);
      });
    }
   
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: isPhone?"center":"flex-start",
        alignItems:'flex-start',
        overflowY: "scroll",
        height: "92%",
        scrollbarWidth: "thin",
        scrollbarColor: "gray transparent",
        backgroundColor:isDark?'transparent':'#efeae2'
      }}
    >
      {skills.map((i) => (
        <SkillComponent key={i.id} {...i} />
      ))}
    </div>
  );
};

export default Skills;
