
import {motion} from 'framer-motion';
import { useSelector } from 'react-redux';


const WelcomeSpinner =()=>{ 


const isDark = useSelector(state=>state.isDark);

// const isDark = true;

return (<div style={{width:'100%', height:'98%', display:'flex', justifyContent:'center', alignItems:'center'}}>

<div style={{display:'flex'}}>

<motion.div initial={{scale:1}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[0.5,1,0.5]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>L</motion.div>
<motion.div initial={{scale:0.5}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[1,0.5,1]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>O</motion.div>


<motion.div initial={{scale:1}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[0.5,1,0.5]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>A</motion.div>
<motion.div initial={{scale:0.5}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[1,0.5,1]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>D</motion.div>


<motion.div initial={{scale:1}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[0.5,1,0.5]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>I</motion.div>
<motion.div initial={{scale:0.5}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[1,0.5,1]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>N</motion.div>


<motion.div initial={{scale:1}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[0.5,1,0.5]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>G</motion.div>
<motion.div initial={{scale:0.5}} transition={{repeat:'Infinity', damping:0, }} animate={{scale:[1,0.5,1]}} style={{fontSize:60, fontWeight:'500', color:isDark?'cyan':'rgb(0, 92, 75)'}}>...</motion.div>


</div>


</div>);
}

export default WelcomeSpinner;