
import { useEffect, useState } from "react";
import {motion} from 'framer-motion';
const ImageLoader = ({ width = 200, height = 200, url, styles, size=30 }) => {
  const [isLoading, setLoading] = useState(true);

  

  const image = new Image();
  image.src = url;
  image.onload = () => {
 setLoading(false);
 
  };

  


  

  return (
    <>
{!isLoading&& <motion.img animate={{scale:1}} style={{width, scale:0, height, ...styles}} src={url} />}

      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection:'column',
            width,
            height
          }}
        >
          <div style={{textAlign:'center', fontSize:size}} >
            <span className="fa fa-spinner  w3-text-white w3-spin"></span>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageLoader;
