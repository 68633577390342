export default function formatDateTime(timestamp) {
    const currentDate = new Date();
    const targetDate = new Date(timestamp);
  
    const timeDifference = currentDate.getTime() - targetDate.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  
    // Check if the timestamp is more than a week old
    if (timeDifference >= oneDay * 7) {
      return targetDate.toDateString();
    }
  
    // Check if the timestamp is yesterday
    if (timeDifference >= oneDay && timeDifference < oneDay * 2) {
      return 'Yesterday';
    }
  
    // Check if the timestamp is today
    if (
      currentDate.toDateString() === targetDate.toDateString() &&
      targetDate.getHours() === currentDate.getHours()
    ) {
      return formatTime(targetDate);
    }
  
    // Return the weekday name for timestamps under a week old
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return weekdays[targetDate.getDay()];
  }
  
  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours}:${minutes}${amOrPm}`;
  }