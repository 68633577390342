

import { useMediaQuery } from '@uidotdev/usehooks';
import {motion} from 'framer-motion';
import { useSelector } from 'react-redux';



const Icon =({faname, active, click})=>{ 

    const isSmall = useMediaQuery("only screen and (max-width : 900px)");
    const isDark = useSelector(state=>state.isDark);




return (<motion.div transition={{damping:0}} onClick={click} whileHover={{x:isSmall?0:20, y:isSmall?0:0}} animate={{x:(!isSmall&&active)?20:0, y:(isSmall&&active)?-20:0}} style={{ x:(!isSmall&&active)?20:0,y:(isSmall&&active)?-20:0,  backgroundColor:active? (isDark?'rgb(0, 92, 75)':'#c6b3ff'):isDark?'rgb(32, 44, 51)':'white', color:'rgb(233, 237, 239)', display:'flex', justifyContent:'center', alignItems:'center', margin:isSmall?10:10,  width:isSmall?100:100, height:isSmall?60:100, borderRadius:20, cursor:'pointer'}}>
<div style={{display:'flex', flexWrap:'wrap', fontSize:isSmall?30:35, color:isDark?'white':'black'}} className={`fa fa-${faname}`}></div> 
</motion.div>);
}

export default Icon;