
import { useMediaQuery } from '@uidotdev/usehooks';
import {motion} from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { profileDoor } from '../../utils/Store';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import DP from './DP';

const Profile =()=>{ 

    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

    const dpURL = useSelector(state=>state.dpURL);
const isDark = useSelector(state=>state.isDark);
const profileDispatcher = useDispatch();

const [enlargeDP, toggleDPsize] = useState(false);

return (<motion.div transition={{damping:0}} animate={{y:0, x:0}} style={{y:isSmallDevice?0:100, x:isSmallDevice?100:0, width:isSmallDevice?'100%':400,}}>
{/**Heading */}
<div style={{display:'flex', padding:'20px', color:'white', height:'20px', backgroundColor: isDark?"#202c33":'#c6b3ff', alignItems:'center'}}>
<div onClick={()=>{profileDispatcher(profileDoor(false))}} className="fa fa-arrow-left w3-large " style={{marginRight:'30px', cursor:'pointer', color:isDark?'white':'black'}}></div>
<div style={{color:isDark?'white':'black',fontWeight:'200', fontSize:20, fontFamily:isSmallDevice?'Pacifico':''}}>Profile</div>

</div>
{/**Pro Pic */}
<div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px'}}>
<div onClick={()=>{toggleDPsize(prev=>!prev)}} style={{backgroundImage:`url('${dpURL}')`, backgroundSize:'cover', width:200, height:200, borderRadius:200, cursor:'pointer'}}></div>
</div>
{enlargeDP && ReactDOM.createPortal(<DP DP={dpURL}  hide={()=>{toggleDPsize(prev=>!prev)}} />, document.getElementById('top'))}
{/**Your Name  */}

<div style={{marginTop:'20px', marginLeft:'20px', marginRight:'20px'}}>
    <div style={{color:isDark?'white':'black', fontWeight:'200',}}>Name</div>
    <div style={{color:isDark?'white':'black', marginTop:'20px'}}>Sarbjeet Singh</div>
    <div style={{color:isDark?'white':'black', marginTop:'20px', fontWeight:'200', fontSize:15}}>👋🔥 Full Stack Developer seeking exciting job and business opportunities! Let's connect and make magic happen! ✨💼 Get in touch with me through the Contact Me section. 📩👨‍💻</div>
</div>

{/**Avout  */}

<div style={{marginTop:'20px', marginLeft:'20px', marginRight:'20px'}}>
    <div style={{color:isDark?'white':'black', fontWeight:'200'}}>About</div>
    <div style={{color:isDark?'white':'black', marginTop:'20px'}}>Having a blast coding and turning ideas into reality! 🚀💻 Join the fun and let's create something awesome together! 😃🎉</div>
</div>

</motion.div>);
}

export default Profile;