import React, { useState, useEffect } from 'react';

function ChatWriter({ text, delay, styles, startTime }) {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);
const [visible, setVisibility] = useState(false);



  useEffect(() => {

setTimeout(() => {
    setVisibility(true);
}, startTime);

    const timer = setTimeout(() => {
      if (index < text.length && visible) {
        setDisplayText(prevDisplayText => prevDisplayText + text.charAt(index));
        setIndex(prevIndex => prevIndex + 1);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [index, text, delay, visible]);

  return <>{visible&& <span dangerouslySetInnerHTML={{__html:displayText}} style={styles}></span>}</>;
}

export default ChatWriter;