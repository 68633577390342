import { useMediaQuery } from "@uidotdev/usehooks";
import sarbstudios from './sarbstudios.jpeg';
import sarbzone from './sarbzone.jpeg';
import {motion} from 'framer-motion';
import { Suspense } from "react";
import WelcomeSpinner from "../../WelcomeSpinner";


const Instagram =()=>{ 


    const isSmall = useMediaQuery("only screen and (max-width : 900px)");



return (<Suspense fallback={<WelcomeSpinner/>}><div style={{display:"flex", height: isSmall?'auto':'80%', flexDirection:isSmall?'column':'row', justifyContent:'center', alignItems:'center'}}>

<a target="_blank" href="https://instagram.com/sarbzone?igshid=OGQ5ZDc2ODk2ZA=="><motion.img whileInView={{x:0,y:0}} style={{x:!isSmall?-100:0, y:isSmall?-100:0, width:200, height:200, margin:20, borderRadius:20}} src={sarbzone} /></a>
<a target="_blank" href="https://instagram.com/sarbstudios?igshid=MmIzYWVlNDQ5Yg=="><motion.img whileInView={{x:0, y:0}} style={{x:!isSmall?100:0, y:isSmall?100:0, width:200, height:200, margin:20, borderRadius:20}} src={sarbstudios} /></a>


</div></Suspense>);
}

export default Instagram;