import { useEffect, useState } from "react";
import ChatComponent from "./ChatComponent";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { saveChats } from "../../utils/Store";
import formatDateTime from "../../utils/Time";
import Theme from "../Theme";
import { useMediaQuery } from "@uidotdev/usehooks";

const Chats = () => {


  const chats = useSelector(state=>state.chats);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
const isDark = useSelector(state=>state.isDark);

  const [descriptions, updateDescriptions] = useState({
    about: "🤖 Ask me anything about me.",
    projects:'👨‍💻My Projects, recent ones are on Top.',
    skills:'🛠️ Hammer, Wrench, ⚛️ React, Node. LOL',
    blog:'📝 Blogs about some of my 👨‍💻Projects.',
    contact:'📱Instagram or 💬Whatsapp would be quick..'
  });

  const [photos, updatePhotos] = useState({ about: "https://picsum.photos/200",
  projects:'https://picsum.photos/200',
  skills:'https://picsum.photos/200',
  blog:'https://picsum.photos/200',
  contact:'https://picsum.photos/200'});


  const [dates, updateDates] = useState({ about: "Today",
  projects:'Tuesday',
  skills:'Monday',
  blog:'12:00AM',
  contact:'5:00PM'});

  useEffect(() => {

  const d= {};
  const p= {};
  const dat = {};
      chats.map((i) => {
        d[i.title] = i.message;
        p[i.title]=i.photo;
        dat[i.title]=i.updatedAt;
        
     });


     updateDescriptions(d);
     updateDates(dat);
     updatePhotos(p);
    

   
  }, [chats]);

  return (
    <div
      id="chats"
      style={{
        overflowY: "auto",
        height: "100%",
        scrollbarColor: "red",
        scrollbarWidth: "thin",
        scrollbarColor: "gray transparent",
      }}
    >
      {/**Search Bar */}

      {/**Chat Comps */}
      <ChatComponent
        photoURL={photos.about}
        title={"About Me"}
        description={descriptions.about}
        time={formatDateTime(dates.about)}
      />
      <ChatComponent
        photoURL={photos.projects}
        title={"Projects"}
        description={descriptions.projects}
        time={formatDateTime(dates.projects)}
      />
      <ChatComponent
        photoURL={photos.skills}
        title={"Skills"}
        description={descriptions.skills}
        time={formatDateTime(dates.skills)}
      />

      <ChatComponent
        photoURL={photos.blog}
        title={"Blog"}
        description={descriptions.blog}
        time={formatDateTime(dates.blog)}
      />
      <ChatComponent
        photoURL={photos.contact}
        title={"Contact Me"}
        description={descriptions.contact}
        time={formatDateTime(dates.contact)}
      />

     {isSmallDevice && <div style={{display:'flex', padding:20, justifyContent:'center', alignItems:'center'}}
     
     > <Theme/> 
     
     </div>}
    </div>
  );
};

export default Chats;
