import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat, updateBlogDetails } from "../../../utils/Store";
import {motion} from 'framer-motion';
import ImageLoader from "../../ImageLoader";

const PhoneBlogComponent = ({ id, title, text, imageUrl, createdAt }) => {
  const isDark = useSelector(state=>state.isDark);

  const blogDispatcher = useDispatch();

  const read = ()=>{

    blogDispatcher(updateBlogDetails({blogName:title, blogId:id, blogText:text, blogPhoto:imageUrl}));
    blogDispatcher(setCurrentChat({title:'Blog --> '+title}));


  }




  return (
    <motion.div
    animate={{x:0}}
    transition={{damping:0}}
    className="w3-card-4"

      style={{x:100,
        margin: 20,
        marginLeft: 20,
        padding: 10,
        width: "95%",
        backgroundColor:isDark?"#202c33":'#c6b3ff',
        borderRadius: 10,
        boxShadow:'none',
        minWidth:100,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      {/**image and title */}
      
        {/* <div
          style={{
            backgroundSize: "cover",
            backgroundImage: `url('${imageUrl}')`,
            width: 150,
            height: 150,
            borderRadius: 10,
          }}
        ></div> */}



        <div
          style={{
            fontFamily: "Pacifico",
            fontSize: 22,
            color: isDark?"rgb(233, 237, 239)":'black',
            marginRight: 10,
            textAlign:'center',
            marginTop:20,
            marginBottom:20,
            
          }}
        >
          {title}
        </div>
        <ImageLoader height={'auto'} width={'50%'} url={imageUrl} size={25} styles={{borderRadius:20, marginBottom:20}} />


      <div style={{ color: isDark?"rgb(233, 237, 239)":'black', }}>{text.slice(0, 200)+ "..."}</div>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between", width:'100%' }}>
        <div></div>
        <div
        onClick={read}
          style={{ backgroundColor: "#111b21", color: "rgb(233, 237, 239)", fontFamily:'Pacifico' }}
          className="w3-button w3-round-xlarge"
        >
          read <span className="fa fa-arrow-right"></span>
        </div>
      </div>
    </motion.div>
  );
};

export default PhoneBlogComponent;
