import { useMediaQuery } from "@uidotdev/usehooks";
import ChatWriter from "../ChatWriter";
import WelcomeScene from "./WelcomeScene";
import { useEffect, useState } from "react";
import {motion} from 'framer-motion';
import { useSelector } from "react-redux";
const Welcome = ({start}) => {

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const [showStart, setStartShow] = useState(false);

const isDark = useSelector(state=>state.isDark);
  useEffect(()=>{


    setTimeout(() => {
      setStartShow(true);
    }, 6500);


  },[]);
  

  return (
    <div
      style={{
        backgroundColor: isDark?"rgba(134,150,160,0.15)":'#efeae2',
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div style={{ width: isSmallDevice?"100%":"50%", height: "50%" }}>
        <WelcomeScene />
      </div>

      <ChatWriter
        text="Welcome to Sarbzone 🎉"
        delay={50}
        startTime={1.5}
        styles={{
          color: isDark?"rgba(233,237,239,0.88)":'black',
          fontSize: "30px",
          fontWeight: "200",
        }}
      />

      <ChatWriter
        text="Crafting 👓 Digital Experiences with Code 👨‍💻"
        delay={50}
        startTime={3000}
        styles={{
          color: isDark?"rgba(233,237,239,0.88)":'black',
          fontSize: "20px",
          fontWeight: "200",
        }}
      />
{isSmallDevice && showStart &&<><motion.div initial={{scale:1}} transition={{repeat:'Infinity'}}  animate={{scale:[1.1,1,1.1]}} onClick={()=>{start();}} style={{fontWeight:'200', marginTop:10}} className="w3-button w3-text-white w3-black w3-large w3-round-large w3-card">start</motion.div></> }
      <div
        style={{
          backgroundColor: "rgb(0, 128, 105)",
          width: "100%",
          height: "5px",
          position: "absolute",
          bottom: 0,
        }}
      ></div>
    </div>
  );
};

export default Welcome;
