import { useMediaQuery } from "@uidotdev/usehooks";
import Chats from "./Chats";
import Header from "./Header";
import Profile from "./Profile";
import AboutMe from "../MessageSection/AboutMe/AboutMe";
import { useDispatch, useSelector } from "react-redux";
import PhoneHeader from "./PhoneHeader";
import { setCurrentChat } from "../../utils/Store";
import Projects from "../MessageSection/Projects/Projects";
import Skills from "../MessageSection/Skills/Skills";
import Blogs from "../MessageSection/Blog/Blogs";
import BlogReading from "../MessageSection/Blog/BlogReading";
import ContactMe from "../MessageSection/ContactMe/ContactMe";
import { useState } from "react";

const ChatSection = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const profileDoor = useSelector((state) => state.profileWindow);
  const currentTitle = useSelector((state) => state.title);
  const currentPhoto = useSelector((state) => state.photoURL);
  const { blogId, blogName, blogText, blogPhoto } = useSelector(
    (state) => state.blogDetails
  );
  const chatDispatcher = useDispatch();
const isDark = useSelector(state=>state.isDark);
  const goBack = () => {
    chatDispatcher(setCurrentChat({ title: "", photoURL: "" }));
  };

  return (
    <div
      style={{
        minWidth: isSmallDevice ? 200 : 400,
        width: isSmallDevice ? window.innerWidth : "auto",
        marginTop: isSmallDevice ? 0 : "20px",
        marginLeft: isSmallDevice ? 0 : "20px",
        marginBottom: isSmallDevice ? 0 : "20px",
        backgroundColor:isDark?'transparent':'white'
      }}
    >
      {/**Heading */}
      {!isSmallDevice && <Header />}
      {isSmallDevice && !currentTitle.includes("Blog -->") && !profileDoor && (
        <PhoneHeader
          goBack={goBack}
          title={currentTitle}
          photoURL={currentPhoto}
        />
      )}
      {profileDoor && <Profile />}

      {!profileDoor && (
        <>
          {isSmallDevice && currentTitle === "About Me" && <AboutMe />}
          {isSmallDevice && currentTitle === "Projects" && <Projects />}
          {isSmallDevice && currentTitle === "Skills" && <Skills />}
          {isSmallDevice && currentTitle === "Blog" && <Blogs />}
          {isSmallDevice && currentTitle === "Contact Me" && <ContactMe />}
          {isSmallDevice && currentTitle === "Blog --> " + blogName && (
            <BlogReading
              id={blogId}
              title={blogName}
              photoURL={blogPhoto}
              text={blogText}
            />
          )}
          {!isSmallDevice && <Chats />}
          {isSmallDevice && currentTitle === "" && <Chats />}{" "}
        </>
      )}
    </div>
  );
};

export default ChatSection;
