import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { setThemeToDark } from "../utils/Store";

const Theme = () => {
  const isDark = useSelector((state) => state.isDark);
  const themeDispatcher = useDispatch();
  const [icon, setIcon] = useState("moon");


  useEffect(()=>{

    if(isDark){
        setIcon('moon');
    }else{
        setIcon('sun');
    }

  },[isDark]);


  return (
    
      <motion.div

whileTap={{scale:1.5}}

        onClick={() => {
          themeDispatcher(setThemeToDark(!isDark));
          window.localStorage.setItem('theme',isDark?'light':'dark');
          if(document.body.style.backgroundColor==='white'){
            document.body.style.backgroundColor= '#111b21';
          }else{
            document.body.style.backgroundColor = 'white';
          }
        
          setIcon((prev) => {
            if (prev === "moon") {
              return "sun";
            } else {
              return "moon";
            }
          });
        }}
      
       
        style={{ color: isDark?"white":"black", fontSize:20,cursor:'pointer' }}
        className={`fa fa-${icon}-o`}
      ></motion.div>
    
  );
};

export default Theme;
