import { useEffect, useRef, useState } from "react";
import ChatWriter from "../../ChatWriter";
import { motion } from "framer-motion";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { saveMessage, startThinking, stopThinking } from "../../../utils/Store";
import { useMediaQuery } from "@uidotdev/usehooks";

const AboutMe = () => {
  const chatRef = useRef();
  const msgsRef = useRef();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 700px)");
const thinking = useSelector(state=>state.thinking);
  const chat = useSelector((state) => state.aboutChat);
  const [oldChat, updateOldChat] = useState([]);
  const [currentChat, updateCurrentChat] = useState([]);

  const isDark = useSelector(state=>state.isDark);

  const chatDispatcher = useDispatch();

  const thinkDispatcher = useDispatch();

  const sendQuery = () => {
    if (chatRef.current.value.trim() !== "") {
      msgsRef.current.scrollTop = msgsRef.current.scrollHeight;

      const query = chatRef.current.value;
      thinkDispatcher(startThinking());
      chatDispatcher(saveMessage({ message: query, who: 1 }));
      updateCurrentChat((prev) => [...prev, { message: query, who: 1 }]);

      chatRef.current.value = "";
      axios
        .post("/ask", { query: query })
        .then((response) => {
          thinkDispatcher(stopThinking());
          chatDispatcher(
            saveMessage({ message: response.data, who: 0 })
          );
          updateCurrentChat((prev) => [
            ...prev,
            { message: response.data, who: 0 },
          ]);

          msgsRef.current.scrollTop = msgsRef.current.scrollHeight;
        })
        .catch((err) => {
          console.log(err.message);
          // chatDispatcher(
          //   saveMessage({ message: "1Could not connect to Server😢😢😢", who: 0 })
          // );
          thinkDispatcher(stopThinking());
          updateCurrentChat((prev) => [
            ...prev,
            { message: "Could not connect to Server😢😢😢", who: 0 },
          ]);
        });
    }
  };

  useEffect(() => {
    const newArray = chat;
    updateOldChat(newArray);

    setTimeout(() => {
      if (!!msgsRef.current) {
        
        msgsRef.current.scrollTop = msgsRef.current.scrollHeight;
      }
    }, 10);
  }, []);

  return (
    <div
      ref={msgsRef}
      style={{
        width: "100%",
        height: "90%",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollbarColor: "gray transparent",
        scrollBehavior: "revert",
        backgroundColor:isDark?'transparent':'#efeae2'
      }}
    >
      <br />
      {oldChat.map((i, index) => {
        if (i.who === 1) {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <motion.div
                key={index}
                animate={{ x: 0 }}
                dangerouslySetInnerHTML={{__html:i.message}}
                style={{
                  x: isSmallDevice?100: 100,
                  color: isDark?"rgb(233, 237, 239)":'black',
                  margin: 5,
                  marginRight: 30,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: isDark?"#005c4b":'white',
                  boxShadow:!isDark?'0px 1px 1px gray':"",
                  borderRadius: 10,
                }}
              ></motion.div>
            </div>
          );
        } else {
          return (
            <motion.div 
            key={index}
              animate={{ scale: 1, x:0 }}
              transition={{damping:0}}
              style={{
                scale: isSmallDevice?1:0,
                x:isSmallDevice?100:0,
                color: isDark?"rgb(233, 237, 239)":'black',
                margin: 5,
                marginLeft: 20,
                boxShadow:!isDark?'0px 1px 1px gray':"",
                padding: 5,
                paddingLeft: 10,
                backgroundColor: isDark?"#202c33":'#c6b3ff',
                width: isSmallDevice?"60%":"40%",
                borderRadius: 10,
              }}
            >
             {oldChat.length>1&& <div dangerouslySetInnerHTML={{__html:i.message}}></div>}
              {oldChat.length===1&& <ChatWriter key={index} text={i.message} delay={5} />}
            </motion.div>
          );
        }
      })}

      {currentChat.map((i, index) => {
        if (i.who === 1) {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <motion.div
                key={index}
                animate={{ x: 0 }}
                style={{
                  x: 100,
                  color: isDark?"rgb(233, 237, 239)":'black',
                  margin: 5,
                  marginRight: 30,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: isDark?"#005c4b":'white',
                  boxShadow:!isDark?'0px 1px 1px gray':"",

                  borderRadius: 10,
                }}
              >
                {i.message}
              </motion.div>
            </div>
          );
        } else {
          return (
            <motion.div
            key={index}
              animate={{ scale: 1 }}
              style={{
                scale: 0,
                color: isDark?"rgb(233, 237, 239)":'black',
                margin: 5,
                marginLeft: 20,

                padding: 5,
                paddingLeft: 10,
                backgroundColor: isDark?"#202c33":'#c6b3ff',
                boxShadow:!isDark?'0px 1px 1px gray':"",
                width: isSmallDevice?"60%":"40%",
                borderRadius: 10,
              }}
            >
              <ChatWriter key={index} text={i.message} delay={5} />
            </motion.div>
          );
        }
      })}

      {/**Thinking BOT */}
{thinking&& <motion.div initial={{ opacity: 0 }}
      animate={{ opacity: [1,0], scale:[1.1,1] }}
      transition={{ duration: 1, repeat: Infinity }} style={{   borderRadius:10, width:100, padding:10, color:"rgb(233, 237, 239)", marginLeft:10, fontSize:30}}>🤖<span className="fa fa-spinner w3-spin"></span></motion.div>}
      {/**GAP */}

      <div style={{ height: 300 }}></div>

      {/**sender */}
      <div
        style={{
          width: "100%",
          height: 60,
          backgroundColor: isDark?"#202c33":'#c6b3ff',
          position: "absolute",
          bottom: 0,
          paddingLeft: 16,
          paddingRight: 16,
          display: "flex",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <input
          placeholder="Type a message..."
          autoFocus={isSmallDevice?false:true}
          ref={chatRef}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendQuery();
            }
          }}
          style={{
            backgroundColor: isDark?"#2a3942":'#fff',
            borderRadius: 10,
            color: isDark?"rgb(233, 237, 239)":'#54656f',
            width: "80%",
            padding: 20,
            borderWidth: 0,
            outline: "none",
          }}
        />
        {/**send btn */}
        <div
          className=" w3-button fa fa-send"
          style={{
            backgroundColor: isDark?"#2a3942":'#fff',
            borderRadius: 10,
            color: isDark?"rgb(233, 237, 239)":'#54656f',
            marginLeft: 20,
            padding: 5,
            width: "10%",
            minWidth:100
          }}
          onClick={sendQuery}
        >
          <br />
          send
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
