import { useMediaQuery } from "@uidotdev/usehooks";
import axios from "axios";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

const Card = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const [errors, updateErrors] = useState(["", "", ""]);
const isDark = useSelector(state=>state.isDark);
  const [isSending, updateSendingStatus] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkValidity = () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;

    const err = [];

    if (name.trim() === "") {
      err.push("Your Name Can't be Blank😬");
    } else {
      err.push("");
    }

    if (!validateEmail(email)) {
      err.push("Email is invalid😬");
    } else {
      err.push("");
    }

    if (message.trim() === "") {
      err.push("Message can't be Blank😬");
    } else {
      err.push("");
    }

    updateErrors(err);
  };

  const sendMessage = () => {

    checkValidity();
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;
if(name.trim()!==""&& validateEmail(email)&&message.trim()!==""){
    updateSendingStatus(true);
    axios
      .post("/sendMessage", {
        name: nameRef.current.value,
        message:messageRef.current.value,
        email:emailRef.current.value
      })
      .then((res) => {
        if (res.data === "success") {
          updateSendingStatus(false);
          nameRef.current.value="";
        messageRef.current.value="";
        emailRef.current.value="";

        updateErrors(prev=>{

            let arr = prev;
arr[2] = "Success!, Will get back to you soon. 😊"
return arr;
        });

        setTimeout(() => {
            updateErrors(["","",""]);
        }, 3000);

        }else{
            updateSendingStatus(false);
            alert("something went wrong.. try again");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });


    }
  };

  const isSmall = useMediaQuery("only screen and (max-width : 900px)");

  return (
    <motion.div
      transition={{ damping: 0 }}
      animate={{ x: 0 }}
      style={{
        x: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      }}
    >
      <div
        className="w3-card-4"
        style={{
          width: isSmall ? "90%" : "70%",
          height: "65vh",
          backgroundColor: isDark?"rgb(0, 92, 75)":'#c6b3ff',
          borderRadius: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            color: isDark?"rgb(233, 237, 239)":'black',
            marginTop: 20,
            alignSelf: "center",
            textAlign: "center",
            marginRight: 10,
            marginLeft: 10,
            
          }}
        >
          Feel free to contact me 📧 for my services if you're interested! 💼
        </div>
        <div
          style={{ position: "relative", width: "100%", textAlign: "center" }}
        >
          <input
            onChange={checkValidity}
            ref={nameRef}
            placeholder="your name..."
            style={{
              outline: "none",
              paddingLeft:20,
              backgroundColor: isDark?"#111b21":'white',
              border: "none",
              width: "90%",
              color: isDark?"rgb(233, 237, 239)":'black',
            }}
            className=" w3-leftbar  w3-margin w3-serif w3-large"
          />

          <div
            style={{
              position: "absolute",
              bottom: -10,
              color: isDark?"cyan":'red',
              width: "100%",
              textAlign: "center",
            }}
          >
            {errors[0]}
          </div>
        </div>
        <div
          style={{ position: "relative", width: "100%", textAlign: "center" }}
        >
          <input
            onChange={checkValidity}
            ref={emailRef}
            placeholder="your email..."
            style={{
              outline: "none",
              paddingLeft:20,
              backgroundColor: isDark?"#111b21":'white',
              border: "none",
              width: "90%",
              color: isDark?"rgb(233, 237, 239)":'black',
            }}
            className=" w3-leftbar  w3-margin w3-serif w3-large"
          />
          <div
            style={{
              position: "absolute",
              bottom: -10,
              color: isDark?"cyan":'red',
              width: "100%",
              textAlign: "center",
            }}
          >
            {errors[1]}
          </div>
        </div>
        <div
          style={{ position: "relative", width: "100%", textAlign: "center" }}
        >
          <textarea
            onChange={checkValidity}
            ref={messageRef}
            placeholder="Message..."
            style={{
              resize: "none",
              outline: "none",
              backgroundColor: isDark?"#111b21":'white',
              paddingLeft:20,
              border: "none",
              width: "90%",
              height: "25vh",
              color: isDark?"rgb(233, 237, 239)":'black',
            }}
            className=" w3-leftbar  w3-margin w3-serif w3-large"
          />
          <div
            style={{
              position: "absolute",
              bottom: 20,
              color: isDark?"cyan":'red',
              width: "100%",
              textAlign: "center",
            }}
          >
            {errors[2]}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            marginBottom: 15,
            marginTop: 10,
          }}
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundColor: "#111b21",
              color: "rgb(233, 237, 239)",
              fontWeight: "200",
            }}
            className=" w3-round-large w3-button w3-hover-black"
          >
            clear
          </motion.div>
          <motion.div
            onClick={sendMessage}
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundColor: "#111b21",
              color: "rgb(233, 237, 239)",
              fontWeight: "200",
            }}
            className=" w3-round-large w3-button w3-hover-black"
          >
            {isSending?<span className="fa fa-spinner w3-spin"></span>:"send"}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
